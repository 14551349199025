
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//import asset
import Entry from '../../assets/images/dashboard/entry.svg';
import Commission from '../../assets/images/dashboard/commission.svg';
import Asset from '../../assets/images/dashboard/asset.svg';
import Supplier from '../../assets/images/new-design/supplier.svg';
import SupplierIcon from '../../assets/images/dashboard/supplier-icon.svg'
import RightArrow from '../../assets/images/new-design/arrow-right-black.svg';
import Marker from '../../assets/images/new-design/marker.svg'
import Share from '../../assets/images/new-design/share.svg'
import Bag from '../../assets/images/new-design/cart.svg'

// import component
import { PrimaryBtn, PrimaryLink, PublishBtn } from "../../components/cta";
import SliderWithNav from "../../components/slider/slider";
import { Loader } from '../../components/loader';
import Icon from '../../components/icon/icon'
import devconfig from "../../config/config";
import APIPost from '../../services/postApi';
import GetURLVar from '../../services/getUrl';
import { _addToBasket } from '../../services/addToBasket';
import { Modal } from 'bootstrap';
import { Toast } from '../../components/note';
import { DeleteConfirm, SuccessPayment } from '../../components/modal';
import _getCurrentValue from '../../services/getCurrentValue';

const DashBoard = (props) => {
    const navigator = useNavigate()

    const [loader, setLoader] = useState(false);
    const [detail, setDetail] = useState({});
    const [media, setMedia] = useState([]);
    const [add, setAdd] = useState(false);
    const [error, setError] = useState(null);
    const [inBasket, setInBasket] = useState(false);
    const [published, setPublished] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (add) {

            var modal = new Modal(document.getElementById("paymentSucess"))
            modal.show()
        }

    }, [add]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 10000)
        }
    }, [error])

    useEffect(() => {
        _getUrl()
    }, [])

    const _getUrl = async () => {
        const query = await GetURLVar()
        _getPropertyDetail(query.id)
    }

    const addToBasket = async () => {

        const response = await _addToBasket(detail.id)
        if (response.status_code === 200) {
            setAdd(true)
            props.added(response.data.item);
            setInBasket(true)
        } else {
            setError(response.message)
        }
    }

    //get property detail

    const _getPropertyDetail = (id) => {
        setLoader(true)
        let url = devconfig.mw + "/property/detail"
        let data = JSON.stringify({
            re_property_id: id
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setDetail(response.data[0].property);
                setInBasket(response.data[0].in_basket);
                setPublished(response.data[0].is_published);
                let tempMediaArr = []
                tempMediaArr.push(response.data[0].property.image_url)
                setMedia(tempMediaArr)
            } else {
                setLoader(false);
                setDetail({});
                setInBasket(false);
            }
        })
    }


    const copyLink = (data) => {
        window.navigator.clipboard.writeText(data).then(() => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <>
            {
                loader ?
                    <Loader height="250px" />
                    :

                    detail && Object.values(detail).length > 0 &&
                    (<div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                        <div className="row">

                            {/* Heading */}
                            <div className='row '>
                                <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                                    <h5 className="e-dashboard-greeting mt-3">
                                        Commercial Real Estate
                                    </h5>
                                    <p className="e-dashboard-sub-title">
                                        Facilisi venenatis in sem praesent purus sit nibh.
                                    </p>
                                </div>
                                <div className="col-lg-2 col-sm-4 col-5 mt-2 pt-4 text-end position-relative px-sm-0 px-0">
                                    <span className="e-basket-cart" onClick={() => navigator("/basket")}>
                                        <img src={Bag} alt="" /> Basket
                                        {
                                            props.basketData.length > 0 ?
                                                <span className='e-basket-label'>{props.basketData.length}</span>
                                                :
                                                null
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 d-flex">
                                    <a className="e-breadcrumb" href="home">Commercial Real Estate</a>
                                    <img src={RightArrow} alt="arrow" className="mx-1" width="12px" height="12px" />
                                    <a className="e-breadcrumb" href="commercial">Properties</a>
                                    <img src={RightArrow} alt="arrow" className="mx-1" width="12px" height="12px" />
                                    <a className="e-breadcrumb-active" href="#">Details</a>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 pe-4">
                                    <SliderWithNav media={media} />
                                </div>
                                <div className="col-lg-12">
                                    <h5 className="e-dashboard-greeting mt-3">
                                        {detail.property_name}
                                    </h5>
                                    <p className="e-location"><img src={Marker} alt="" onClick={() => { window.open(detail.location_url) }} />
                                        {detail.location}</p>
                                </div>
                                <div className="row">
                                    <h6 className="e-filled">Filled</h6>
                                    <div className="col-lg-6 col-12 e-detail-progress mb-sm-3 mb-md-0 mb-3">
                                        <p className="e-amount">
                                            <span> ₹ {_getCurrentValue(parseFloat((detail.filled_value?.split("₹")[1])?.replace(/,/g, '')))}</span> Funded of ₹ {_getCurrentValue(parseFloat((detail.asset_value?.split("₹")[1])?.replace(/,/g, '')))}
                                        </p>
                                        <div className={` progress e-progress`} style={{ height: "28px" }}>
                                            <div className={`progress-bar e-progress-bar `} style={{ width: `${detail.filled}`, height: '28px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                                <span className='text-end pe-2'>{detail.filled}</span>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="col-lg-6 col-12 text-lg-end d-flex justify-content-end">
                                        {/* <a
                                            href={
                                                "https://www.linkedin.com/shareArticle?mini=true&url=" +
                                                window.location.href
                                            }
                                        > */}
                                        <div className="e-online-social-btn text-center  ms-3 position-relative" onClick={() => copyLink(window.location.href)}>

                                            <Icon
                                                icon="copy"
                                                color="#0077B5"
                                                width="22"
                                                height="22"

                                            />

                                            {
                                                copied ?
                                                    <div className='position-absolute e-copied-toast'>Copied</div>
                                                    :
                                                    null
                                            }
                                        </div>

                                        <a
                                            href={
                                                "https://www.linkedin.com/shareArticle?mini=true&name=" +
                                                window.location.href
                                            }
                                            target="_blank"
                                        >
                                            <div className="e-online-social-btn text-center  ms-3">
                                                <Icon
                                                    icon="linkedIn"
                                                    color="#0077B5"
                                                    width="22"
                                                    height="22"

                                                />
                                            </div>
                                        </a>
                                        <a
                                            href={"https://www.facebook.com/sharer.php?u=" + window.location.href}
                                            target="_blank"
                                        >
                                            <div className="e-online-social-btn text-center  ms-3">
                                                <Icon
                                                    icon="fb_sqaure"
                                                    width="18"
                                                    height="18"
                                                />
                                            </div>
                                        </a>
                                        <a
                                            href={"https://twitter.com/intent/tweet?url=" + window.location.href}
                                            target="_blank"
                                        >
                                            <div className="e-online-social-btn text-center mx-3">
                                                <Icon
                                                    icon="twitter"
                                                    width="20.38"
                                                    height="16.57"
                                                // className="mt-2"
                                                />
                                            </div>
                                        </a>


                                        {
                                            inBasket ?
                                                <PublishBtn />
                                                :
                                                published ?
                                                    <PrimaryBtn
                                                        name="Unpublish"
                                                        className="e-unpublish"
                                                        toggle="modal"
                                                        target="#deleteConfirm "
                                                    />
                                                    :
                                                    <PrimaryBtn
                                                        name="Add to basket"
                                                        handleClick={() => {
                                                            addToBasket()
                                                        }}
                                                    />
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Cards */}
                            <div className='row  e-detail mt-4'>
                                <h6 className='e-filled mb-3'>Deal highlights</h6>
                                <div className='col-lg-3 mb-sm-3 mb-md-0 mb-3'>
                                    <div className='e-homepage-card p-4 '>
                                        <div className='col-6'>
                                            <img src={Entry} alt="" />
                                        </div>
                                        <div className='col-12'>
                                            <p>
                                                Entry yield
                                            </p>
                                            <h6 className='e-value'>{detail.entry_yield}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 mb-sm-3 mb-md-0 mb-3'>
                                    <div className='e-homepage-card p-4 '>
                                        <div className='col-6'>
                                            <img src={Commission} alt="" />
                                        </div>
                                        <div className='col-12'>
                                            <p>
                                                Commission
                                            </p>
                                            <h6 className='e-value'>{detail.commission}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 mb-sm-3 mb-md-0 mb-3'>
                                    <div className='e-homepage-card p-4 '>
                                        <div className='col-6'>
                                            <img src={Asset} alt="" />
                                        </div>
                                        <div className='col-12'>
                                            <p>
                                                Asset value
                                            </p>
                                            <h6 className='e-value'>₹ {_getCurrentValue(parseFloat((detail.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 mb-sm-3 mb-md-0 mb-3'>
                                    <div className='e-homepage-card p-4 '>
                                        <div className='col-6'>
                                            <img src={SupplierIcon} alt="" />
                                        </div>
                                        <div className='col-12'>
                                            <p>
                                                Supplier
                                            </p>
                                            <img src={detail.supplier_image} alt="" className="mt-2" width="60px" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row pt-4 mt-3'>
                                <h6 className='e-filled mb-2'>Overview</h6>
                                <p className='e-detail-info'>{detail.overview}</p>
                            </div>

                            <div className='row pt-4 mt-3'>
                                <h6 className='e-filled mb-2'>Tenant</h6>
                                <p className='e-detail-info'>{detail.tenant}</p>
                            </div>

                            <div className='row pt-4 mt-3'>
                                <h6 className='e-filled mb-2'>Location</h6>
                                <p className='e-detail-info'>{detail.location}</p>
                                <iframe
                                    src={detail.location_url}
                                    width="800"
                                    height="600"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>

                        </div>
                    </div>)
            }
            {
                error && (
                    <Toast data={error} />
                )
            }
            {
                add ?
                    <SuccessPayment
                        editScore={false}
                        // assetAdded={assetAdded}
                        heading={"Added to basket Successfully"}//type
                        status={true}//status

                        from="portfolio-tracker"
                        close={() => {
                            setAdd(false);
                            // props.added(false)

                        }}
                    />
                    :
                    null
            }
            <DeleteConfirm
                id={detail.id}
                heading="Unpublish deal"
                content={`<p>Are you sure you want to unpublsih <span style="color:red">${detail.property_name}</span> ?<br> <span style="font-size:16px; font-weight: 500;color: #010101;
            ">Please note this process can't be undone</span></p>`}
                btnName="Unpublish"
                btnClass="e-unpublish"
                unpublished={() => {
                    _getPropertyDetail(detail.id)
                    // setPublished(false)
                }}
                close={() => {

                }} />
        </>
    )
}

export default DashBoard;