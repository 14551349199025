import { useEffect, useState } from "react";
import { Modal } from "bootstrap";

//import asset
import Radio from '../../assets/images/dashboard/radio.svg';

import RightArrow from '../../assets/images/new-design/arrow-right-black.svg';
import Delete from '../../assets/images/dashboard/delete.svg'


// import component
import { PrimaryBtn } from "../../components/cta";

import DealCard from "../../components/card/deal-card";
import { DeleteConfirm, SuccessPayment } from "../../components/modal";
import devconfig from "../../config/config";
import APIGet from "../../services/getApi";
import { NoAssets } from "../../components/note";

const Basket = (props) => {

    const [basketData, setBasketData] = useState([]);
    const [id, setId] = useState(null)


    useEffect(() => {
        setBasketData(props.basketData)
    }, [props.basketData])

    useEffect(() => {
        if (id !== null) {
            var modal = new Modal(document.getElementById("deleteConfirm"))
            modal.show()
        }
    }, [id])

    const _publishBasket = () => {
        let url = devconfig.mw + '/basket/publish';

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                var modal = new Modal(document.getElementById("paymentSucess"))
                modal.show()
                props.added(true)
            }
        })
    }

    return (
        <div className="e-main-content e-dashboard ps-lg-5 ps-2 pb-5 mb-5" >
            <div className="row">

                {/* Heading */}
                <div className="col-12  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                    <h5 className="e-dashboard-greeting mt-3">
                        Commercial Real Estate
                    </h5>
                    <p className="e-dashboard-sub-title">
                        Facilisi venenatis in sem praesent purus sit nibh.
                    </p>
                </div>

                <div className="col-lg-12 d-flex">
                    <a className="e-breadcrumb" href="commercial">Commercial Real Estate</a>
                    <img src={RightArrow} alt="arrow" className="mx-1" width="12px" height="12px" />
                    <a className="e-breadcrumb" href="commercial">Properties</a>
                    <img src={RightArrow} alt="arrow" className="mx-1" width="12px" height="12px" />
                    <a className="e-breadcrumb-active" href="#">Basket</a>
                </div>



                {/*------------- basket items cards list------------- */}
                <div className="col-12">
                    <h6 className="e-dashboard-greeting mt-4 mb-3">
                        Investments
                    </h6>
                </div>


                {
                    basketData.length > 0 ?
                        basketData.map((item) => {
                            return (
                                <>
                                    <div className="row pe-2 pe-lg-0 pe-md-0 mb-3 position-relative">

                                        <div className="col-lg-9 col-md-9 col-sm-12">
                                            <DealCard
                                                horizontal={true}
                                                detail={true}
                                                data={item.re_data}
                                                fromBasket={true}
                                                added={(data) => props.added(data)}
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 d-flex align-items-center">
                                            <img
                                                src={Delete}
                                                alt=""
                                                className="e-delete"

                                                onClick={() => {
                                                    setId(item.re_data.id)
                                                }} />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <PrimaryBtn
                                                name="Publish"
                                                left={true}
                                                image={Radio}
                                                className="e-publish-btn"
                                                handleClick={() => {
                                                    _publishBasket()
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>

                            )
                        })
                        :
                        <NoAssets />
                }


            </div>

            {/* Publish successfull modal */}
            <SuccessPayment
                editScore={false}
                heading={"Published Successfully"}//type
                status={true}//status

                from="portfolio-tracker"
                close={() => {

                }}
            />

            {/* Delete confirmation modal */}
            {
                id !== null ?
                    <DeleteConfirm
                        id={id}
                        deleted={(data) => {
                            props.added(data);
                            setBasketData(data)
                            setId(null)
                        }}
                        close={() => {
                            // props.added(false);
                            // setId(null)
                        }}
                    />
                    :
                    null
            }
        </div>
    )
}

export default Basket;