import devconfig from "../config/config";
import APIPost from "./postApi";


export async function _addToBasket(id) {
    return new Promise(async (resolve, reject) => {
        var url = devconfig.mw + "/basket/add";
        var data = JSON.stringify({
            re_property_id: id
        })
        try {
            let response = await APIPost(url, data);
            resolve(response);
        } catch (err) {
            resolve({
                'status': 'error'
            });
        }
    });
}