/*
 *   File : card .js
 *   Author : https://evoqueinnovativelab.com
 *   Description : C Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-12-2021
*/

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/*import styles*/
import styles from '../../styles/components/dealCard.module.scss';


/* import assets */
import AddToBasket from '../../assets/images/new-design/basket-white.svg'
import Marker from '../../assets/images/new-design/marker.svg'
import { PrimaryBtn, PrimaryLink, PublishBtn } from '../cta';
import { DeleteConfirm, SuccessPayment } from '../modal';
import { Modal } from 'bootstrap';
import { _addToBasket } from '../../services/addToBasket';
import { Toast } from '../note';
import _getCurrentValue from '../../services/getCurrentValue';

const DealCard = (props) => {
    const navigate = useNavigate()
    const [add, setAdd] = useState(false);
    const [error, setError] = useState();
    const [added, setAdded] = useState(props.inBasket);
    const [title, setTitle] = useState(null);
    const [id, setId] = useState(null);
    const [published, setPublished] = useState(false);

    useEffect(() => {
        if (add) {

            var modal = new Modal(document.getElementById("paymentSucess"))
            modal.show()
        }

    }, [add]);

    useEffect(() => {
        setPublished(props.published)
    }, [props.published])

    useEffect(() => {
        if (title !== null) {
            var modal = new Modal(document.getElementById("deleteConfirm"))
            modal.show()
        }
    }, [title]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 10000)
        }
    }, [error])

    const addToBasket = async () => {

        const response = await _addToBasket(props.data.id)
        if (response.status_code === 200) {
            setAdd(true)
            setAdded(true)
            props.added(response.data.item)
        } else {
            setError(response.message)
        }
    }


    return (
        <>
            {
                props.horizontal ?
                    <div className={`${styles.e_deal_card} card border-0`} id={props.id}>
                        <div className="row">
                            <div className="col-lg-5">
                                <img src={props.data.image_url} alt="" className={styles.e_thumbnail} style={{ height: "100%", borderRadius: "14px" }} />
                            </div>
                            <div className='col-lg-7'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h5 className={styles.e_title}>{props.data.property_name}</h5>
                                        <p className={styles.e_location}><img src={Marker} alt="" className={styles.e_marker} onClick={() => {
                                            window.open(props.data.location_url)
                                        }} />
                                            {props.data.location}</p>
                                    </div>
                                    {
                                        props.detail ?
                                            null
                                            :
                                            <div className='row mt-2'>
                                                <div className='col-lg-4'>
                                                    <h6 className={styles.e_content}>Target IRR</h6>
                                                    <p className={styles.e_content_info}>{props.data.target_irr}</p>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <h6 className={styles.e_content}>Commission</h6>
                                                    <p className={styles.e_content_info}>{props.data.commission}</p>
                                                </div>
                                                <div className='col-lg-4'>
                                                    <h6 className={styles.e_content}>Asset value</h6>
                                                    <p className={styles.e_content_info}>₹ {_getCurrentValue(parseFloat((props.data.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</p>
                                                </div>
                                            </div>
                                    }
                                </div>
                                {/* <div className='col-lg-12'>
                                    <h6 className={styles.e_content}>Tenant name</h6>
                                    <p className={styles.e_content_info}>Tenant-I - Century old international manufacturer
                                        Tenant - 2 - Indian manufacturer</p>
                                </div> */}
                                <div className='row mb-2 mt-auto'>
                                    <div className='col-lg-5'>
                                        <h6 className={styles.e_content}>Supplier</h6>
                                        <img src={props.data.supplier_image} alt="" width="50px" />
                                    </div>
                                    <div className='col-lg-7'>
                                        {
                                            props.detail ?
                                                <>
                                                    <h6 className={styles.e_content}>Commission</h6>
                                                    <p className={styles.e_content_info}>{props.data.commission}</p>
                                                </>
                                                :
                                                <>
                                                    <h6 className={styles.e_content}>Filled</h6>
                                                    <div className={`${styles.e_progress} progress e-progress`} >
                                                        <div className={`${styles.e_progress_bar} progress-bar e-progress-bar `} style={{ width: `${props.data.filled}`, height: '20px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                                            <span className='text-end pe-2'>{props.data.filled}</span>
                                                        </div>

                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                                {
                                    props.fromBasket ?
                                        null
                                        :

                                        added ?
                                            <PublishBtn className="mt-3 w-100" />
                                            :
                                            published === true ?
                                                <div className='col-lg-12 mt-3'>
                                                    <PrimaryBtn
                                                        name="Unpublish"
                                                        image={AddToBasket}
                                                        left={true}
                                                        iconClass="me-3"
                                                        className={"e-unpublish w-100"}

                                                        handleClick={() => {
                                                            setTitle(props.data.property_name);
                                                            setId(props.data.id)
                                                        }}
                                                    />

                                                </div>
                                                :
                                                <div className='col-lg-12 mt-3'>
                                                    <PrimaryBtn
                                                        name="Add to basket"
                                                        image={AddToBasket}
                                                        left={true}
                                                        iconClass="me-3"
                                                        className={styles.e_add_btn}

                                                        handleClick={() => {
                                                            addToBasket()
                                                        }}
                                                    />

                                                </div>
                                }
                                {
                                    props.fromBasket ?
                                        null
                                        :
                                        <div className='col-lg-12 mt-3 text-center'>
                                            <PrimaryLink
                                                name="View details"
                                                className={styles.e_view}
                                                handleClick={() => {
                                                    navigate("/commercial-detail?id=" + props.data.id)
                                                }
                                                } />
                                        </div>
                                }
                            </div>
                        </div>


                    </div>
                    :
                    <div className={`${styles.e_deal_card} card border-0`} id={props.id}>
                        <div className="row">
                            <div className="col-lg-12">
                                <img src={props.data.image_url} alt="" className={styles.e_thumbnail} />
                            </div>
                            <div className='col-lg-12'>
                                <h5 className={styles.e_title}>{props.data.property_name}</h5>
                                <p className={styles.e_location}>
                                    <img src={Marker} alt="" className={styles.e_marker} onClick={() => {
                                        window.open(props.data.location_url)
                                    }} />
                                    {props.data.location}</p>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-lg-4'>
                                    <h6 className={styles.e_content}>Target IRR</h6>
                                    <p className={styles.e_content_info}>{props.data.target_irr}</p>
                                </div>
                                <div className='col-lg-4'>
                                    <h6 className={styles.e_content}>Commission</h6>
                                    <p className={styles.e_content_info}>{props.data.commission}</p>
                                </div>
                                <div className='col-lg-4'>
                                    <h6 className={styles.e_content}>Asset value</h6>
                                    <p className={styles.e_content_info}>₹ {_getCurrentValue(parseFloat((props.data.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <h6 className={styles.e_content}>Tenant name</h6>
                            <p className={styles.e_content_info}>{props.data.tenant ? props.data.tenant : '--'}</p>
                        </div>
                        <div className='row mb-2 mt-auto'>
                            <div className='col-lg-5'>
                                <h6 className={styles.e_content}>Supplier</h6>
                                <img src={props.data.supplier_image} alt="" width="50px" />
                            </div>
                            <div className='col-lg-7'>
                                <h6 className={styles.e_content}>Filled</h6>
                                <div className={`${styles.e_progress} progress e-progress`} >
                                    <div className={`${styles.e_progress_bar} progress-bar e-progress-bar `} style={{ width: `${props.data.filled}`, height: '20px' }} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                                        <span className='text-end pe-2'>{props.data.filled}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {
                            props.fromPublish ?
                                <>

                                    <div className='col-lg-12 mt-3'>
                                        <PrimaryBtn
                                            name="View details"
                                            // image={AddToBasket}
                                            // left={true}
                                            iconClass="me-3"
                                            className={styles.e_add_btn}
                                            handleClick={() => {
                                                navigate("/commercial-detail?id=" + props.data.id)
                                            }}
                                        />

                                    </div>

                                    <div className='col-lg-12 mt-3 text-center'>
                                        <PrimaryLink
                                            name="Unpublish"
                                            className={styles.e_unpublish}
                                            handleClick={() => {
                                                setTitle(props.data.property_name);
                                                setId(props.data.id)
                                            }} />
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        added ?
                                            <PublishBtn className="mt-3 w-100" />
                                            :
                                            published === true ?
                                                <div className='col-lg-12 mt-3'>
                                                    <PrimaryBtn
                                                        name="Unpublish"
                                                        image={AddToBasket}
                                                        left={true}
                                                        iconClass="me-3"
                                                        className={"e-unpublish w-100"}

                                                        handleClick={() => {
                                                            setTitle(props.data.property_name);
                                                            setId(props.data.id)
                                                        }}
                                                    />

                                                </div>
                                                :
                                                <div className='col-lg-12 mt-3'>
                                                    <PrimaryBtn
                                                        name="Add to basket"
                                                        image={AddToBasket}
                                                        left={true}
                                                        iconClass="me-3"
                                                        className={styles.e_add_btn}

                                                        handleClick={() => {
                                                            addToBasket()
                                                        }}
                                                    />

                                                </div>
                                    }
                                    <div className='col-lg-12 mt-3 text-center'>
                                        <PrimaryLink
                                            name="View details"
                                            handleClick={() => {
                                                navigate("/commercial-detail?id=" + props.data.id)
                                            }}
                                            className={styles.e_view} />
                                    </div>
                                </>
                        }

                    </div>
            }
            {
                add ?
                    <SuccessPayment
                        editScore={false}
                        // assetAdded={assetAdded}
                        heading={"Added to basket Successfully"}//type
                        status={true}//status

                        from="portfolio-tracker"
                        close={() => {
                            setAdd(false);
                            // props.added(true)

                        }}
                    />
                    :
                    null
            }

            {
                error && (
                    <Toast data={error} />
                )
            }
            {/* {console.log(published)} */}
            {
                title ?
                    <DeleteConfirm
                        id={id}
                        heading="Unpublish deal"
                        content={`<p>Are you sure you want to unpublsih <span style="color:red">${title}</span> ?<br> <span style="font-size:16px; font-weight: 500;color: #010101;
            ">Please note this process can't be undone</span></p>`}
                        btnName="Unpublish"
                        btnClass="e-unpublish"
                        unpublished={() => {
                            props.unpublished();
                            // setPublished(false)
                        }}
                        close={() => {
                            setTitle(null);
                            setId(null);
                        }} />
                    :
                    null
            }
        </>
    )
}

export default DealCard;
