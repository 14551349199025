/*
 *   File : invest.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Invest Modal
 *   Integrations :
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*import packages*/
import React, { useEffect, useState, } from 'react';
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
/*import styles*/
import styles from '../../styles/components/success.module.scss';
import style from '../../styles/components/loader.module.scss';
import Close from '../../assets/images/new-design/modalClose.svg';


/*import assets */
import Success from '../../assets/images/new-design/successGif.gif';
import Failed from '../../assets/images/new-design/failGif.gif';
import { PrimaryBtn, PrimaryLink, SecondaryBtn } from '../cta';


export default function SuccessPayment(props) {

    useEffect(() => {
        var myModal = document.getElementById('paymentSucess');
        myModal.addEventListener('shown.bs.modal', () => {
            setTimeout(() => {
                $('#close-btn').trigger('click');
            }, 2000)
        })
    }, []);


    useEffect(() => {
        var myModal = document.getElementById('paymentSucess');
        myModal.addEventListener('hidden.bs.modal', () => {
            props.close()
        })
    }, []);




    return (
        <div className={`${styles.e_success_modal} modal fade`} id="paymentSucess" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="successLabel" aria-hidden="true" >
            <div className={`modal-dialog modal-dialog-centered ${styles.e_modal_dialog}`}>
                <div className={`${styles.e_content} modal-content position-relative`}>
                    <div className={`${styles.e_modal_header} text-center pt-4 pb-3`}>
                        <h5 className=''>{props.heading}</h5>
                        <img
                            id="close-btn"
                            src={Close}
                            className={`${styles.e_modal_close}`}
                            data-bs-dismiss="modal"
                            onClick={() => { props.editScore ? window.location.href = "/home" : props.close() }}
                        />
                    </div>
                    <div className='modal-body'>
                        <div className="container px-3">

                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    {props.status ? <img src={Success} alt="" className={`${styles.e_success_icon}`} style={{ marginTop: "40px" }} /> : <img src={Failed} alt="f" className={`${styles.e_success_icon}`} style={{ marginTop: "40px" }} />}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-center mt-3">
                                    <h6>{props.subTitle ? props.subTitle : props.title}</h6>
                                    {/* <p></p> */}
                                </div>
                                <div className='col-12 text-center'>
                                    {
                                        props.content ?
                                            props.content :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="row  mt-3 pb-4">
                                {
                                    props.mandate || props.sip ?
                                        null
                                        :
                                        <div className="col-lg-12 text-center">
                                            {
                                                props.editScore === false && (
                                                    <>
                                                        {
                                                            props.status ?
                                                                <>
                                                                    {
                                                                        props.from !== 'portfolio-tracker' && (
                                                                            <div className='d-flex align-items-end justify-content-center'>
                                                                                <PrimaryLink name={"See details"}
                                                                                    className={`${styles.e_link}`} handleClick={() => {
                                                                                        window.location.href = "/orders"
                                                                                    }} />
                                                                            </div>
                                                                        )
                                                                    }

                                                                </>
                                                                :
                                                                <div className='d-flex align-items-end justify-content-center'>
                                                                    <PrimaryBtn name="Retry" className={`${styles.e_btn}`} cancel="modal" />
                                                                </div>
                                                        }
                                                    </>
                                                )

                                            }
                                            {
                                                props.editScore === true && (
                                                    <PrimaryLink
                                                        className={`${styles.e_link} mt-2`}
                                                        name="Go to Dashboard"
                                                        handleClick={() => window.location.href = "/home"}
                                                    />
                                                )
                                            }
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>

    )
}



