/*
 *   File : login.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";


/*import components*/
import Input from '../../components/input/loginInput';
import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note/index';

/*import styles */

/*import assets*/
import LogoMobile from '../../assets/images/menu/logo.svg';
import ShowPassword from '../../assets/images/auth/password.svg';
import HidePassword from '../../assets/images/auth/hide.svg';

/*import context*/

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

const Login = (props) => {
	let navigator = useNavigate();
	const [error, setError] = useState(null);

	const [userName, setUserName] = useState(null);
	const [password, setPassword] = useState(null);
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const [loader, setLoader] = useState(false);


	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null)
			}, 10000)
		}
	}, [error])




	const validate = () => {


		var emailPatter = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
		var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);

		if (!emailPatter.test(userName)) {
			setError("Invalid email")
			return false
		} else if (!pattern.test(password)) {
			setError("Password should contain one alphabet, one special character and one digit with minimum 8 characters");
			return false
		}
		else {
			validateCredential()
		}
	}

	const validateCredential = () => {
		setLoader(true);

		let url = devconfig.mw + '/auth/login';
		let data = JSON.stringify({
			email: userName,
			password: password
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				localStorage.setItem("access_token", response.data.aceess_token)
				window.location.href = "/";
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
			}
		})

	}



	return (
		<>

			<div className='container e-new-onboard mt-4'>

				{/* Header logo */}

				<div className='row'>
					<div className='col-12 ps-0 d-flex justify-content-center'>
						<img
							className='e-outer-logo mt-4'
							src={LogoMobile}
							width="172px"
						/>
					</div>
				</div>

				{/* Body */}
				<div className='row mt-lg-5 mt-md-5 mt-xs-3 mt-3 pt-lg-5 pt-md-5 pb-lg-5 pb-md-5'>
					<div className='col-lg-10 col-md-12 col-sm-12  offset-lg-1'>
						<div className='row'>

							{/* Video section */}
							{/* {
										window.innerWidth > 575 && (
											<div className='col-lg-6 col-md-6 col-sm-12 gx-0 order-lg-1 mt-lg-0 mt-md-0 mt-5 mt-sm-5  order-md-1 order-sm-2 order-2'>
												<div className='e-video-player-container'>
													<VideoPlayer />
												</div>
											</div>
										)
									} */}


							{/* Form */}
							<div className='col-lg-4 col-md-4 col-sm-12 offset-lg-4 offset-md-4 gx-0 order-lg-2 order-md-2 order-sm-1 order-1'>

								<form
									className='e-login-form'
									onSubmit={(e) => e.preventDefault()}
								>

									<>
										<label>Email</label>
										<Input
											id="email-input"
											type="text"
											placeholder="email"
											value={userName}
											needBlurEvent={true}
											needFocusEvent={true}
											focusIn={() => {

											}}

											valueChange={(value) => { setUserName(value) }}
											focusOut={(value) => {

											}}
										/>
									</>
									<div className="pwd-container-login pt-3">
										<label>Password</label>
										<Input
											id="email-input"
											type={isRevealPwd ? "text" : "password"}
											placeholder="password"
											value={password}
											className="e-input-pwd"
											valueChange={(value) => { setPassword(value) }}
											focusOut={(value) => {

											}}
										/>
										<img src={isRevealPwd ? HidePassword : ShowPassword} onClick={() => setIsRevealPwd(prevState => !prevState)} />
									</div>



									{
										userName && password ? (
											<PrimaryBtn
												className={loader ? "w-100 mt-3 e-auth-submit e-btn-loader" : "w-100 mt-3 e-auth-submit"}
												name="Login"
												handleClick={() => {
													validate()

												}}

											/>
										) : (

											<PrimaryBtn
												disabled={true}
												className="w-100 mt-3 e-auth-submit"
												name="Login"
												// right={true}
												// image={BtnArrow}
												iconClass="ms-2"

											/>
										)
									}
								</form>
							</div>
						</div>
					</div>

				</div>

				{
					error && (
						<Toast data={error} />
					)
				}
			</div>


		</>
	)
}

export default Login;



// </div>
// <div className='col-12 mt-5 mb-5 text-center'>
// 	<h6 className='e-help-title'>Still not sure?</h6>
// 	<a href="https://wa.me/+918655872100" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
// 		<WatsappBtn left={true} image={Icon} className="me-lg-3 me-md-3 me-sm-3 e-login-whatsapp" />
// 	</a>
// 	<a href={calendlyUrl} target="_blank"  rel="noopener noreferrer" style={{ textDecoration: "none" }}>
// 		<CalendlyBtn left={true} image={CalendlyICon} />
// 	</a>
// </div>
// </div>
// <MintdSecurity/>
// </div>	