
/*import package*/
import React, { useEffect, useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


/*import custom component*/

import Login from './containers/auth/login.js';
import SplashScreen from './components/loader/splashScreen.js';

import CommercialHome from './containers/commercial/home.js';
import CommercialDetail from './containers/detail/index.js';
import Basket from './containers/basket/index.js';
import Publish from './containers/publish/index.js';

import Redirect from './containers/auth/redirect';
import Sidebar from './components/menu/sidebar.js';


import Startup from './containers/startup/index.js';
import IPO from './containers/pre-ipo/index.js';
import Lease from './containers/lease/index.js';
import Customers from './containers/customers.js/index.js';
import LeaseDetail from './containers/lease/deatil'
import Dashboard from './containers/newDesign/dashboard';

import Home from './containers/newDesign/home';


/*import context data provider*/
import MintdLogo from './assets/images/menu/logo.svg';
import SideBarMenu from './assets/images/new-design/Menu.svg';


/*Service import */
import devconfig from './config/config.js';
import APIGet from './services/getApi.js';
import NotFound from './containers/notfound/index.js';

import APIPost from './services/postApi.js';




export default function App(props) {
	const [completed, setCompleted] = useState(1)
	const [dataLoader, setDataLoader] = useState(false)
	const [splashScreen, setSplashScreen] = useState(false);

	const [openSideBar, setOpenSideBar] = useState(false);
	const [tour, setTour] = useState(false);
	const [basketData, setBasketData] = useState([]);
	const [added, setAdded] = useState(false);

	useEffect(() => {
		if (localStorage.getItem("access_token")) {
			_getStepsDetail(true)
		}
	}, [localStorage.getItem("access_token")]);

	const _getStepsDetail = (status) => {
		setSplashScreen(status);
		const url = devconfig.mw + "/steps/get";
		APIGet(url).then((resposne) => {
			if (resposne.status_code === 200) {
				setCompleted(resposne.data.step + 1)
				setSplashScreen(false);
			} else {
				setCompleted(1);
				setSplashScreen(false);
			}
		});
	}

	const updateStep = () => {
		const url = devconfig.mw + "/steps/update";
		const data = JSON.stringify({
			step: 1
		})
		APIPost(url, data).then((resposne) => {
			if (resposne.status_code === 200) {
				// setCompleted(2);
				_getStepsDetail(false);
			} else {
				setCompleted(1);
			}
		});
	}



	return (
		<>
			{
				splashScreen ?
					<SplashScreen />
					:

					<>
						{
							localStorage.getItem("access_token") ?
								<>
									{
										(

											<Router>
												<div className="container-fluid">
													<div className="row ">
														<div className={tour === "true" ? "d-none" : 'col-lg-2 pe-0'}>
															<div className='e-side-bar-lg'>
																<Sidebar added={added} getData={(data) => { setBasketData(data) }} closeMenu={() => { }} />
															</div>
														</div>
														<div className={
															tour === "true" ?
																"col-lg-10 col-md-12 col-sm-12 px-0 e-main-content e-tour-container"
																:
																"col-lg-10 col-md-12 col-sm-12 px-0 e-main-content"
														}
														>
															<div className={tour === "true" ? "d-none" : ''}>
																<div className={
																	completed === 4 ?
																		'e-inner-small-screen-title d-flex justify-content-between px-lg-4 px-3 pb-3'
																		:
																		'e-inner-small-screen-title d-flex justify-content-between px-2 pb-3'
																}>
																	<img src={MintdLogo} className="mt-4" />
																	<button
																		className='e-menu-btn mt-4'
																		onClick={() => {
																			setOpenSideBar(true);
																		}}
																	>
																		<img src={SideBarMenu} />
																	</button>
																</div>
																<div
																	className={
																		openSideBar === true ?
																			'e-small-screen-menu e-menu-over-lay'
																			:
																			'e-small-screen-menu'
																	}
																	style={{ display: openSideBar && 'block', right: openSideBar && '0' }}
																>
																	{
																		window.innerWidth <= 992 ?
																			<Sidebar added={added} closeMenu={() => setOpenSideBar(false)} getData={(data) => { setBasketData(data) }} />
																			:
																			null
																	}
																</div>
															</div>

															{
																(
																	<Routes>
																		{
																			completed === 5 ?
																				<Route exact path="/" element={<Home basketData={basketData} added={(data) => { setBasketData(data); if (completed < 5) { _getStepsDetail() } }} />} />
																				:
																				<Route exact path="/" element={<Dashboard basketData={basketData}
																					added={(data) => {
																						setBasketData(data)
																						if (completed < 5) {
																							_getStepsDetail(false)
																						}
																					}}
																					step={completed}
																					callBack={() => {
																						setAdded(true)
																						_getStepsDetail(false);
																					}}
																					updateStep={() => {
																						if (completed === 1) {
																							updateStep();
																						} else {
																							_getStepsDetail(false);
																						}
																					}} />} />
																		}

																		<Route exact path="/commercial" element={<CommercialHome basketData={basketData} added={(data) => { setBasketData(data); console.log(data); if (completed < 5) { _getStepsDetail(false) } }} />} />
																		<Route exact path="/commercial-detail" element={<CommercialDetail basketData={basketData} added={(data) => { setBasketData(data); if (completed < 5) { _getStepsDetail(false) } }} />} />
																		<Route exact path="/basket" element={<Basket basketData={basketData} added={(data) => { setBasketData(data); if (completed < 5) { _getStepsDetail(false) } }} />} />
																		<Route exact path="/publish" element={<Publish basketData={basketData} added={(data) => { setAdded(data) }} />} />
																		{/* <Route exact path="/home" element={<Home basketData={basketData} added={(data) => setBasketData(data)} />} /> */}
																		<Route exact path="/startup" element={<Startup basketData={basketData} added={(data) => setBasketData(data)} />} />
																		<Route exact path="/pre-ipo" element={<IPO basketData={basketData} added={(data) => setBasketData(data)} />} />
																		<Route exact path="/lease-financing" element={<Lease basketData={basketData} added={(data) => setBasketData(data)} />} />
																		<Route exact path="/lease-detail" element={<LeaseDetail basketData={basketData} added={(data) => setBasketData(data)} />} />
																		<Route exact path="/customers" element={<Customers />} />
																		<Route exact path="/auth/kite/redirect" element={<Redirect />} />
																		<Route path="*" element={<NotFound />} />
																	</Routes>
																)
															}
														</div>
													</div>

												</div>
											</Router>
										)
									}
								</>
								:
								<Router>
									<div className='container-fluid'>
										<Routes>
											<Route exact path="/" element={<Login />} />
											<Route exact path="/login" element={<Login />} />
											<Route exact path="/auth/kite/redirect" element={<Redirect />} />
											{/* <Route path="*" element={<NotFound />} /> */}
										</Routes>
									</div>
								</Router>
						}
						{/* <Support /> */}
					</>
			}
		</>
	)
}

