import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//import asset


import Bag from '../../assets/images/new-design/cart.svg'
import Thumbnail from '../../assets/images/new-design/card-thumbnail.png'

// import component

import devconfig from "../../config/config";
import DealCard from "../../components/card/deal-card";
import APIPost from "../../services/postApi";
import { Loader } from "../../components/loader";
import Nodata from "../../components/note/nodata-placeholder";

const Startup = (props) => {

    const navigator = useNavigate();


    return (
        <>
            {
                <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                    <div className="row">

                        {/* Heading */}
                        <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                            <h5 className="e-dashboard-greeting mt-3">
                                Startup investing
                            </h5>
                            <p className="e-dashboard-sub-title">
                                Facilisi venenatis in sem praesent purus sit nibh.
                            </p>
                        </div>
                        <div className="col-lg-2 col-sm-4 col-5 mt-2 pt-4 text-end position-relative px-sm-0 px-0">
                            <span className="e-basket-cart" onClick={() => navigator("/basket")}>
                                <img src={Bag} alt="" /> Basket
                                {
                                    props.basketData.length > 0 ?
                                        <span className='e-basket-label'>{props.basketData.length}</span>
                                        :
                                        null
                                }
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Nodata note="Coming soon" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Startup;