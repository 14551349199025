import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
//Asset import
import Revenue from '../../assets/images/dashboard/revenue.svg';
import Assets from '../../assets/images/dashboard/assets.svg';
import Publish from '../../assets/images/dashboard/publish.svg';
import Total from '../../assets/images/dashboard/total.svg';
import Bag from '../../assets/images/new-design/cart.svg';
import Menu from '../../assets/images/dashboard/more.svg';
import Whatsapp from '../../assets/images/dashboard/whatsapp.svg'
import Thumbnail from '../../assets/images/new-design/card-thumbnail.png';
import Profile from '../../assets/images/dashboard/profile.svg';
import ArrowActive from '../../assets/images/new-design/arrow-blue-active.svg';
//Component import

import { Loader } from '../../components/loader';

// import  from '../../components/modal';

/*import sevices*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';

import RebalanceUpdate from '../../components/modal/rebalanceUpdate';
import { DealCard } from '../../components/card';
import { PrimaryLink } from '../../components/cta';
import _getCurrentValue from '../../services/getCurrentValue';



const Home = (props) => {


    const navigator = useNavigate();
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);
    const [publishedList, setPublishedList] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    const [customerList, setCustomerList] = useState([]);

    useEffect(() => {
        _getPropertyList(true);
        _getDashboardData();
    }, [])



    const _generateGreeting = () => {

        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Good Morning!";
        } else if (currentHour >= 12 && currentHour < 15) {
            return "Good Afternoon!";
        } else if (currentHour >= 15 && currentHour < 20) {
            return "Good Evening!";
        } else if (currentHour >= 20 && currentHour < 3) {
            return "Good Night!";
        } else {
            return "Good Evening!"
        }
    }



    //get property list

    const _getPropertyList = (status) => {
        setLoader(status)
        let url = devconfig.mw + "/property/list"
        let data = JSON.stringify({
            page_num: 1,
            page_size: 100,
            published: false
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setList(response.data)
            } else {
                setLoader(false);
                setList([]);
            }
        })
    }

    const _getDashboardData = () => {
        setLoader(true)
        let url = devconfig.mw + "/dashabord/get"

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                setDashboardData(response.data)
                setPublishedList(response.data.published_deals);
                setCustomerList(response.data.customers);
            } else {
                setLoader(false)
                setDashboardData({});
                setPublishedList([]);
                setCustomerList([])
            }
        })
    }

    return (
        <>
            {
                loader ?
                    <Loader height="250px" />
                    :

                    (
                        <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                            <div className="row">

                                {/* Heading */}
                                <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-0 mt-0  mb-lg-3 mb-md-3 mb-sm-2 mb-2">
                                    <h5 className="e-dashboard-greeting mt-3">
                                        {`Hey Charul, ${_generateGreeting()}`}
                                    </h5>
                                    <p className="e-dashboard-sub-title ">
                                        Here is your portfolio summary
                                    </p>
                                </div>
                                <div className="col-lg-2 col-sm-4 col-5 mt-2 pt-4 text-end position-relative px-sm-0 px-0">
                                    <span className="e-basket-cart" onClick={() => navigator("/basket")}>
                                        <img src={Bag} alt="" /> Basket
                                        {
                                            props.basketData.length > 0 ?
                                                <span className='e-basket-label'>{props.basketData.length}</span>
                                                :
                                                null
                                        }
                                    </span>
                                </div>

                                {/* Portfolio summary cards */}

                                <div className='row pe-0'>
                                    <div className='col-lg-3 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Revenue} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Revenue
                                                </p>
                                                <h6 className='e-value'><span className='e-rupee'>₹</span>{dashboardData && dashboardData.analytics && dashboardData.analytics.revenue ? _getCurrentValue(dashboardData.analytics.revenue) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Assets} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Available assets
                                                </p>
                                                <h6 className='e-value'>{dashboardData && dashboardData.analytics && dashboardData.analytics.available_assets ? (dashboardData.analytics.available_assets) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Publish} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Published asstes
                                                </p>
                                                <h6 className='e-value'>{dashboardData && dashboardData.analytics && dashboardData.analytics.published_assets ? (dashboardData.analytics.published_assets) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 pe-0 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Total} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Total customers
                                                </p>
                                                <h6 className='e-value'>{dashboardData && dashboardData.analytics && dashboardData.analytics.total_customers ? (dashboardData.analytics.total_customers) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-lg-4 mt-sm-0  mt-0'>
                                {
                                    publishedList.length > 0 ?
                                        <div className='col-lg-8 mb-sm-4 mb-lg-0 mb-4 e-home-scroll'>
                                            <div className='e-homepage-card position-relative pb-5 e-small-screen-deals' style={{ borderRadius: "16px" }}>
                                                {

                                                    <>
                                                        <table width="100%" className="e-published-list">
                                                            <tr >
                                                                <th style={{ padding: "30px 0px 30px 30px", width: "240px" }}>Published deals</th>
                                                                <th style={{ padding: "30px 20px 30px 20px" }}>Commission</th>
                                                                <th style={{ padding: "30px 30px 30px 0px" }}>Asset value</th>
                                                            </tr>
                                                            <tbody>
                                                                {
                                                                    publishedList && publishedList.slice(0, 5).map((item) => {
                                                                        return (
                                                                            <tr>
                                                                                <td style={{ padding: "10px 0px 10px 30px" }}>{item.property.property_name}</td>
                                                                                <td className='e-commission'>{item.property.commission}</td>
                                                                                <td style={{ padding: "10px 30px 10px 0px" }}>₹ {_getCurrentValue(parseFloat((item.property.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div className='col-lg-12 text-end my-3 pe-3 e-view-more'>
                                                            <PrimaryLink
                                                                name="See all"
                                                                className="text-end ms-auto"
                                                                right={true}
                                                                image={ArrowActive}
                                                                iconClass="ms-2"
                                                                handleClick={() => navigator("/publish")}
                                                                marginTop="-4px" />

                                                        </div>
                                                    </>

                                                }


                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    customerList.length > 0 ?

                                        <div className='col-lg-4 mb-sm-4 mb-lg-0 mb-4'>

                                            <div className='e-homepage-card p-4 pb-5 position-relative' style={{ borderRadius: "16px" }}>
                                                <div className='row mb-4'>
                                                    <div className='col-10 e-title'>
                                                        Recent customers
                                                    </div>

                                                </div>
                                                {
                                                    customerList.slice(0, 5).map((item) => {
                                                        return (
                                                            <div className='row mt-3'>
                                                                <div className='col-md-2 col-3'>
                                                                    <img src={Profile} alt="" />
                                                                </div>
                                                                <div className='col-md-8 col-7 ps-2 align-self-center'>
                                                                    <h6 className='e-name'>{item.fnam}</h6>
                                                                    <p className='e-designation pt-0'>{item.email}</p>
                                                                </div>
                                                                <div className='col-2 d-flex align-items-center ps-0'>
                                                                    <img src={Whatsapp} alt="" />
                                                                </div>

                                                            </div>
                                                        )
                                                    })

                                                }
                                                {
                                                    customerList.length && (
                                                        <div className='col-lg-12 text-end my-3 pe-3 e-view-more'>
                                                            <PrimaryLink
                                                                name="See all"
                                                                className="text-end ms-auto"
                                                                right={true}
                                                                image={ArrowActive}
                                                                iconClass="ms-2"
                                                                handleClick={() => navigator("/customers")}
                                                                marginTop="-4px" />

                                                        </div>
                                                    )
                                                }
                                                {/* <div className='row mt-3'>
                                            <div className='col-2'>
                                                <img src={Profile} alt="" />
                                            </div>
                                            <div className='col-8 ps-2 align-self-center'>
                                                <h6 className='e-name'>Bessie cooper</h6>
                                                <p className='e-designation pt-0'>Member</p>
                                            </div>
                                            <div className='col-2 d-flex align-items-center ps-0'>
                                                <img src={Whatsapp} alt="" />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-2'>
                                                <img src={Profile} alt="" />
                                            </div>
                                            <div className='col-8 ps-2 align-self-center'>
                                                <h6 className='e-name'>Bessie cooper</h6>
                                                <p className='e-designation pt-0'>Member</p>
                                            </div>
                                            <div className='col-2 d-flex align-items-center ps-0'>
                                                <img src={Whatsapp} alt="" />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-2'>
                                                <img src={Profile} alt="" />
                                            </div>
                                            <div className='col-8 ps-2 align-self-center'>
                                                <h6 className='e-name'>Bessie cooper</h6>
                                                <p className='e-designation pt-0'>Member</p>
                                            </div>
                                            <div className='col-2 d-flex align-items-center ps-0'>
                                                <img src={Whatsapp} alt="" />
                                            </div>
                                        </div> */}
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            <div className="row pe-2 pe-lg-0 pe-md-0 mt-4">
                                <div className="col-12">
                                    <h6 className="e-dashboard-greeting mt-2 mb-3">
                                        Recent deals
                                    </h6>
                                </div>
                                {
                                    list && list.map((item, key) => {
                                        return (
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                <DealCard
                                                    thumbnail={Thumbnail}
                                                    data={item.property}
                                                    inBasket={item.in_basket}
                                                    published={item.is_published}
                                                    added={(data) => {
                                                        // console.log(data)
                                                        props.added(data);

                                                    }}
                                                    unpublished={() => {
                                                        _getDashboardData()
                                                        _getPropertyList(false)
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4  mt-lg-0 mt-md-0">

                                    <DealCard
                                        thumbnail={Thumbnail}
                                        title="Navi mumbai Office Opportunity" />

                                </div> */}
                            </div>
                        </div>
                    )
            }


        </>

    )
}

export default Home