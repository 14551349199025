import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//import asset


import Bag from '../../assets/images/new-design/cart.svg'
import Thumbnail from '../../assets/images/new-design/card-thumbnail.png'

// import component

import devconfig from "../../config/config";
import APIPost from "../../services/postApi";
import { LeaseCard } from "../../components/card";
import { Loader } from "../../components/loader";

const Lease = (props) => {

    const navigator = useNavigate();
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);


    useEffect(() => {
        _getPropertyList()
    }, [])

    //get property list

    const _getPropertyList = () => {
        setLoader(true)
        let url = devconfig.mw + "/property/list"
        let data = JSON.stringify({
            page_num: 1,
            page_size: 200
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setList(response.data)
            } else {
                setLoader(false);
                setList([]);
            }
        })
    }


    return (
        <>
            {
                loader ?
                    <Loader height="250px" />
                    :
                    <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                        <div className="row">

                            {/* Heading */}
                            <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                                <h5 className="e-dashboard-greeting mt-3">
                                    Lease financing
                                </h5>
                                <p className="e-dashboard-sub-title">
                                    Facilisi venenatis in sem praesent purus sit nibh.
                                </p>
                            </div>
                            <div className="col-lg-2 col-sm-4 col-5 mt-2 pt-4 text-end position-relative px-sm-0 px-0">
                                <span className="e-basket-cart" onClick={() => navigator("/basket")}>
                                    <img src={Bag} alt="" /> Basket
                                    {
                                        props.basketData.length > 0 ?
                                            <span className='e-basket-label'>{props.basketData.length}</span>
                                            :
                                            null
                                    }
                                </span>
                            </div>
                        </div>

                        {/* deals card */}
                        <div className="row pe-2 pe-lg-0 pe-md-0">
                            {
                                list && list.map((item, key) => {
                                    return (
                                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                            <LeaseCard
                                                thumbnail={Thumbnail}
                                                data={item.property}
                                                inBasket={item.in_basket}
                                                added={(data) => props.added(data)}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
            }
        </>
    )
}

export default Lease;