import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Home from '../../assets/images/sidebar/home.svg';
import HomeActive from '../../assets/images/sidebar/home-active.svg';
import Commercial from '../../assets/images/sidebar/commercial.svg';
import CommercialActive from '../../assets/images/sidebar/commercial-active.svg';
import SEBI from '../../assets/images/sidebar/sebi.svg';
import Archive from '../../assets/images/sidebar/archive.svg'
import Startup from '../../assets/images/sidebar/bar.svg'
import Portfolio from '../../assets/images/sidebar/portfolio.svg';
import PortfolioActive from '../../assets/images/sidebar/portfolio-active.svg';

import Calender from '../../assets/images/sidebar/calender.svg';
import CalenderActive from '../../assets/images/sidebar/calender-active.svg';

import Clipboard from '../../assets/images/sidebar/clipboard.svg';
import ClipboardActive from '../../assets/images/sidebar/clipboard-active.svg';

import Book from '../../assets/images/sidebar/book.svg';
import Message from '../../assets/images/sidebar/message.svg';
import Users from '../../assets/images/sidebar/users.svg';
import Logout from '../../assets/images/sidebar/logout.svg';
import Close from '../../assets/images/new-design/modalClose.svg';

import MintdLogo from '../../assets/images/menu/logo.svg';

import styles from '../../styles/components/sidebar.module.scss';

import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import { useEffect } from 'react';


const Sidebar = (props) => {

    const [menuIndex, setMenuIndex] = useState(null);
    const [basketData, setBasketData] = useState([]);

    useEffect(() => {
        _getBasketList()
    }, [])

    useEffect(() => {
        if (props.added === true) {
            _getBasketList()
        }
    }, [props.added])

    const menu = [
        {
            title: 'ASSETS  ',
            data: [
                {
                    name: 'Home',
                    icon: Home,
                    iconActive: HomeActive,
                    link: '/',
                    active: true
                },
                {
                    name: 'Commercial RE',
                    icon: Commercial,
                    iconActive: CommercialActive,
                    link: '/commercial',
                    active: true,
                    id: props.id,
                    tourLink: props.link
                },
                {
                    name: 'Lease financing',
                    icon: Calender,
                    link: "/lease-financing",
                    iconActive: CalenderActive,
                    active: false,
                    opacity: '0.5'
                },
                {
                    name: 'Startup Investing',
                    icon: Startup,
                    link: "#",
                    // iconActive: OrdersActive,
                    active: false,
                    opacity: '0.5'
                },
                {
                    name: 'Pre-IPO',
                    icon: Portfolio,
                    link: "#",
                    iconActive: PortfolioActive,
                    active: false,
                    opacity: '0.5'
                },


                // {
                //     name: 'SEBI-Regulated',
                //     icon: SEBI,
                //     link: "#",
                //     // iconActive: BellActive,
                //     active: true,

                // },
                // {
                //     name: 'Consolidated',
                //     icon: Clipboard,
                //     link: "#",
                //     iconActive: ClipboardActive,
                //     active: props.activeUser
                // },
                // {
                //     name: 'Providers',
                //     icon: Archive,
                //     link: "#",
                //     // iconActive: SettingsActive,
                //     active: props.activeUser,
                //     id: props.settingId,
                //     tourLink: props.link
                // },
                {
                    name: 'Logout',
                    icon: Logout,
                    link: null,
                    // iconActive: SettingsActive,
                    active: props.activeUser,
                    id: props.settingId,
                    tourLink: props.link
                },
            ]
        },
    ]


    let navigator = useNavigate();

    const _getBasketList = () => {
        let url = devconfig.mw + '/basket/list'

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                props.getData(response.data.item)
            } else {
                props.getData([])
            }
        })
    }

    return (
        <div className={`${styles.e_sidebar_wrapper} e-side-bar p-3`} >
            <div id={props.settings}>
                <img src={MintdLogo} className="mt-2 e-sidebar-logo" width="153px" />
                <img src={Close} className="e-side-abr-close-btn" onClick={() => props.closeMenu()} />
                {
                    menu.map((section, key) => {
                        return (
                            <div className={`${styles.e_section}`}>
                                <h6 className={`${styles.e_title}`} >
                                    {section.title}
                                </h6>
                                {
                                    section.data.map((item, index) => {
                                        return (
                                            <div
                                                style={{ opacity: item.opacity }}
                                                id={item.id}
                                                className={window.location.pathname === '/commercial-detail' && index === 1 && key === 0 ?
                                                    `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                    :
                                                    window.location.pathname === '/scrip-detail' && index === 1 && key === 0 ?
                                                        `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                        :
                                                        window.location.pathname === '/allocation' && index === 2 && key === 0 ?
                                                            `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                            :
                                                            window.location.pathname === item.link ?
                                                                `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                                :
                                                                (window.location.pathname === item.link || window.location.pathname === "/") && key === 0 && index === 0 ?
                                                                    `${styles.e_navbar} ${styles.e_active_nav} position-relative  d-block`
                                                                    :
                                                                    `${styles.e_navbar} position-relative  d-block`
                                                }
                                                onMouseEnter={() => { setMenuIndex(index) }}
                                                onMouseLeave={() => { setMenuIndex(null) }}
                                                onClick={() => {
                                                    if (item.tourLink) {
                                                        props.callBack();
                                                        return
                                                    }
                                                    if (item.link === null) {
                                                        localStorage.clear();
                                                        window.location.href = "/login"
                                                    }

                                                    if (item.active) {
                                                        navigator(item.link);
                                                        props.closeMenu();
                                                    }
                                                }}
                                            >
                                                <img
                                                    src={
                                                        window.location.pathname === '/scrip-detail' && index === 1 && key === 0 ?
                                                            item.iconActive
                                                            :
                                                            window.location.pathname === '/allocation' && index === 2 && key === 0 ?
                                                                item.iconActive
                                                                :
                                                                window.location.pathname === item.link ?
                                                                    item.iconActive
                                                                    :
                                                                    item.icon
                                                    }
                                                    className="me-2"
                                                />
                                                {item.name}
                                                {
                                                    item.name === 'Notifications' && props.count > 0 && (
                                                        <span className={`${styles.e_count} ms-4`}>{props.count}</span>
                                                    )
                                                }
                                                {
                                                    item.active === false ? (
                                                        <>
                                                            {
                                                                menuIndex === index && (
                                                                    <span className={`${styles.e_badge}`}> Coming soon </span>
                                                                )
                                                            }
                                                        </>

                                                    ) : (
                                                        null
                                                    )
                                                }
                                                {
                                                    item.name === 'Mintd 360' && (
                                                        <span className='position-absolute e-new-badge'>
                                                            New
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div >
    )
}

export default Sidebar;