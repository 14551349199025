import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/loader";

//import asset

// import component


import Nodata from "../../components/note/nodata-placeholder";
import devconfig from "../../config/config";
import APIPost from "../../services/postApi";

const Customers = (props) => {

    const navigator = useNavigate();
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        _getCustomerList();
    }, [])

    const _getCustomerList = () => {
        setLoader(true)
        var url = devconfig.mw + "/dashabord/customers"

        var data = JSON.stringify({
            page_num: 1,
            page_size: 100
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setList(response.data)
            } else {
                setLoader(false);
                setList([])
            }
        })
    }


    return (
        <>
            {
                loader ?
                    <Loader height="250px" />
                    :

                    <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                        <div className="row">

                            {/* Heading */}
                            <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                                <h5 className="e-dashboard-greeting mt-3">
                                    Customers
                                </h5>
                                <p className="e-dashboard-sub-title">
                                    Facilisi venenatis in sem praesent purus sit nibh.
                                </p>
                            </div>

                        </div>
                        {
                            list.length > 0 ?
                                <div className="row">
                                    <div className='col-lg-12'>

                                        <div className='row mb-4'>
                                            <h5 className='col-10 e-dashboard-greeting'>
                                                Recent customers
                                            </h5>

                                        </div>
                                        <div className="row e-home-scroll   ">
                                            <div className="e-homepage-card p-0 e-small-screen-deals " style={{ borderRadius: "16px" }}>
                                                <table width="100%" className="e-published-list mb-5">
                                                    <tr >
                                                        <th style={{ padding: "30px 0px 30px 30px", width: "240px" }}>First name</th>
                                                        <th style={{ padding: "30px 20px 30px 20px" }}>Last name</th>
                                                        <th style={{ padding: "30px 20px 30px 20px" }}>Mobile</th>
                                                        <th style={{ padding: "30px 20px 30px 20px" }}>Email</th>
                                                        <th style={{ padding: "30px 30px 30px 0px" }}>Created</th>
                                                    </tr>
                                                    <tbody>
                                                        {
                                                            list && list.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ padding: "10px 0px 10px 30px" }}>{item.fname}</td>
                                                                        <td style={{ padding: "10px 20px 10px 20px" }}>{item.lname}</td>
                                                                        <td style={{ padding: "10px 20px 10px 20px" }}>{item.mobile}</td>
                                                                        <td style={{ padding: "10px 20px 10px 20px" }}>{item.email}</td>
                                                                        <td style={{ padding: "10px 30px 10px 0px" }}>{item.created}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-lg-12">
                                        <Nodata note="Coming soon" />
                                    </div>
                                </div>
                        }

                    </div>
            }
        </>
    )
}

export default Customers;