import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


//Asset import

import Bag from '../../assets/images/new-design/cart.svg';
import Thumbnail from '../../assets/images/new-design/card-thumbnail.png';

//Component import



// import  from '../../components/modal';

/*import sevices*/

import { DealCard } from '../../components/card';
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import { Loader } from '../../components/loader';
import _getCurrentValue from '../../services/getCurrentValue';



const CommercialHome = (props) => {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        _getPropertyList(true);
    }, [])

    //get property list

    const _getPropertyList = (status) => {
        setLoader(status)
        let url = devconfig.mw + "/property/list"
        let data = JSON.stringify({
            page_num: 1,
            page_size: 200,
            published: false
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setList(response.data)
            } else {
                setLoader(false);
                setList([]);
            }
        })
    }


    return (
        <>
            {
                loader ?
                    <Loader height="250px" />
                    :

                    (
                        <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                            <div className="row">

                                {/* Heading */}
                                <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-0 mt-0  mb-lg-3 mb-md-3 mb-sm-2 mb-2">
                                    <h5 className="e-dashboard-greeting mt-3">
                                        Commercial Real Estate
                                    </h5>
                                    <p className="e-dashboard-sub-title ">
                                        Facilisi venenatis in sem praesent purus sit nibh.
                                    </p>
                                </div>
                                <div className="col-lg-2 col-sm-4 col-5 mt-2 pt-4 text-end position-relative px-sm-0 px-0">
                                    <span className="e-basket-cart" onClick={() => navigate("/basket")}>
                                        <img src={Bag} alt="" /> Basket
                                        {
                                            props.basketData.length > 0 ?
                                                <span className='e-basket-label'>{props.basketData.length}</span>
                                                :
                                                null
                                        }
                                    </span>
                                </div>

                                {/* Portfolio summary cards */}

                                {/* <div className='row pe-0'>
                                    <div className='col-lg-3 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Revenue} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Revenue
                                                </p>
                                                <h6 className='e-value'><span className='e-rupee'>₹</span>{dashboardData && dashboardData.analytics && dashboardData.analytics.revenue ? _getCurrentValue(dashboardData.analytics.revenue) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Assets} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Available assets
                                                </p>
                                                <h6 className='e-value'>{dashboardData && dashboardData.analytics && dashboardData.analytics.available_assets ? (dashboardData.analytics.available_assets) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Publish} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Published asstes
                                                </p>
                                                <h6 className='e-value'>{dashboardData && dashboardData.analytics && dashboardData.analytics.published_assets ? (dashboardData.analytics.published_assets) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 pe-0 mb-sm-4 mb-lg-0 mb-4'>
                                        <div className='e-homepage-card p-4 '>
                                            <div className='col-6'>
                                                <img src={Total} alt="" />
                                            </div>
                                            <div className='col-12'>
                                                <p>
                                                    Total customers
                                                </p>
                                                <h6 className='e-value'>{dashboardData && dashboardData.analytics && dashboardData.analytics.total_customers ? (dashboardData.analytics.total_customers) : "--"}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className='row mt-lg-4 mt-sm-0 mt-0'>
                                <div className='col-lg-8 mb-sm-4 mb-lg-0 mb-4'>
                                    <div className='e-homepage-card ' style={{ borderRadius: "16px" }}>
                                        {
                                            publishedList.length > 0 ?
                                                <>
                                                    <table width="100%" className="e-published-list">
                                                        <tr >
                                                            <th style={{ padding: "30px 0px 30px 30px", width: "240px" }}>Published deals</th>
                                                            <th style={{ padding: "30px 20px 30px 20px" }}>Commission</th>
                                                            <th style={{ padding: "30px 30px 30px 0px" }}>Asset value</th>
                                                        </tr>
                                                        <tbody>
                                                            {
                                                                publishedList && publishedList.map((item) => {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ padding: "10px 0px 10px 30px" }}>{item.property.property_name}</td>
                                                                            <td className='e-commission'>{item.property.commission}</td>
                                                                            <td style={{ padding: "10px 30px 10px 0px" }}>₹ {_getCurrentValue(parseFloat((item.property.asset_value?.split("₹")[1])?.replace(/,/g, '')))}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div className='col-lg-12 text-end my-3 pe-3'>
                                                        <PrimaryLink
                                                            name="See all"
                                                            className="text-end ms-auto"
                                                            right={true}
                                                            image={ArrowActive}
                                                            iconClass="ms-2"
                                                            handleClick={() => navigate("/publish")}
                                                            marginTop="-4px" />

                                                    </div>
                                                </>
                                                :
                                                <div className='p-4'>
                                                    <div className='e-title'>Recently published</div>
                                                    <h6 className='e-dashboard-greeting mt-3'>
                                                        Navi Mumbai Office
                                                        Opportunity
                                                    </h6>
                                                </div>
                                        }


                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='e-homepage-card p-4' style={{ borderRadius: "16px" }}>
                                        <div className='row mb-4'>
                                            <div className='col-10 e-title'>
                                                Recent customers
                                            </div>
                                            <div className='col-2'>
                                                <img src={Menu} alt="" />
                                            </div>
                                        </div>
                                        {
                                            customerList && customerList.map((item) => {
                                                return (
                                                    <div className='row mt-3'>
                                                        <div className='col-2'>
                                                            <img src={Profile} alt="" />
                                                        </div>
                                                        <div className='col-8 ps-2 align-self-center'>
                                                            <h6 className='e-name'>{item.name}</h6>
                                                            <p className='e-designation pt-0'>Member</p>
                                                        </div>
                                                        <div className='col-2 d-flex align-items-center ps-0'>
                                                            <img src={Whatsapp} alt="" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="row pe-2 pe-lg-0 pe-md-0 mt-4">
                                <div className="col-12">
                                    <h6 className="e-dashboard-greeting mt-2 mb-3">
                                        Recent deals
                                    </h6>
                                </div>
                                {
                                    list && list.map((item, key) => {
                                        return (
                                            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                                <DealCard
                                                    horizontal={true}
                                                    thumbnail={Thumbnail}
                                                    data={item.property}
                                                    inBasket={item.in_basket}
                                                    published={item.is_published}
                                                    added={(data) => {
                                                        props.added(data);
                                                    }}
                                                    unpublished={() => {
                                                        _getPropertyList(false)
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4 ">
                                <DealCard
                                    horizontal={true}
                                    thumbnail={Thumbnail}
                                    title="Navi mumbai Office Opportunity" />

                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mt-4 ">
                                <DealCard
                                    published={true}
                                    horizontal={true}
                                    thumbnail={Thumbnail}
                                    title="Navi mumbai Office Opportunity" />

                            </div> */}
                            </div>
                        </div>
                    )
            }


        </>

    )
}

export default CommercialHome