import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";


//import asset
import CRE from '../../assets/images/new-design/cre.svg';
import AddBasket from '../../assets/images/new-design/add-basket.svg';
import HeadBasket from '../../assets/images/new-design/head-basket.svg';
import Publish from '../../assets/images/new-design/publish.svg';
import Info from '../../assets/images/new-design/info.svg';
import BtnArrow from '../../assets/images/new-design/btn-arrow.svg';

import Bag from '../../assets/images/new-design/cart.svg'
import Thumbnail from '../../assets/images/new-design/card-thumbnail.png'

// import component
import { PrimaryBtn, } from "../../components/cta";


import DealCard from "../../components/card/deal-card";
import { Loader } from "../../components/loader";
import devconfig from "../../config/config";
import APIPost from "../../services/postApi";



const onboardCard = [
    {
        title: 'Head to CRE',
        desc: 'Magna faucibus purus amet cras arcu non nunc. Elementum praesent.',
        icon: CRE,
        link: "/commercial",
        tooltip: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
    },
    {
        title: 'Add to basket',
        desc: 'Magna faucibus purus amet cras arcu non nunc. Elementum praesent.',
        icon: AddBasket,
        link: "/commercial",
        tooltip: 'View the assets in your portfolio curated specially for you.'
    },
    {
        title: 'Head to basket',
        desc: 'Magna faucibus purus amet cras arcu non nunc. Elementum praesent.',
        icon: HeadBasket,
        link: "/basket",
        tooltip: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
    },
    {
        title: 'Publish',
        desc: 'Magna faucibus purus amet cras arcu non nunc. Elementum praesent.',
        icon: Publish,
        link: "/basket",
        tooltip: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
    }
]



const DashBoard = (props) => {

    const navigator = useNavigate();
    const [loader, setLoader] = useState(false);
    const [list, setList] = useState([]);
    const [openToolTip, setOpenToolTip] = useState(null);

    useEffect(() => {
        _getPropertyList()
    }, [])

    const _generateGreeting = () => {

        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Good Morning!";
        } else if (currentHour >= 12 && currentHour < 15) {
            return "Good Afternoon!";
        } else if (currentHour >= 15 && currentHour < 20) {
            return "Good Evening!";
        } else if (currentHour >= 20 && currentHour < 3) {
            return "Good Night!";
        } else {
            return "Good Evening!"
        }
    }

    // const EditBtn = (props) => {
    //     let status_index = SIGNUP_STATUS.findIndex((item) => item.status === userData.signup_status);
    //     if (props.step == 3 && status_index < 5) {
    //         return (
    //             <PrimaryLink
    //                 className="mt-3"
    //                 name="Edit"
    //                 handleClick={() => navigator('/onboard')}
    //             />
    //         )

    //     }
    //     return null;
    // }

    const _getPropertyList = () => {
        setLoader(true)
        let url = devconfig.mw + "/property/list"
        let data = JSON.stringify({
            page_num: 1,
            page_size: 200
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setList(response.data)
            } else {
                setLoader(false);
                setList([]);
            }
        })
    }


    return (
        <>
            {
                loader ?
                    <Loader height="250px" />
                    :
                    <div className="e-main-content e-dashboard px-lg-5 px-3 pb-5 mb-5" >
                        <div className="row">

                            {/* Heading */}
                            <div className="col-lg-10 col-sm-8 col-7  mt-lg-3 mt-sm-3 mt-3 pt- 5">
                                <h5 className="e-dashboard-greeting mt-3">
                                    {`Hey Charul, ${_generateGreeting()}`}
                                </h5>
                                <p className="e-dashboard-sub-title">
                                    Please complete these steps to start investing
                                </p>
                            </div>
                            <div className="col-lg-2 col-sm-4 col-5 mt-2 pt-4 text-end position-relative px-sm-0 px-0">
                                <span className="e-basket-cart" onClick={() => navigator("/basket")}>
                                    <img src={Bag} alt="" /> Basket
                                    {
                                        props.basketData.length > 0 ?
                                            <span className='e-basket-label'>{props.basketData.length}</span>
                                            :
                                            null
                                    }
                                </span>
                            </div>

                            {/* Cards */}
                            <div className="col-12 mt-lg-3 mt-xs-0 mt-0">
                                <div className="d-flex e-dashboard-onboard-card-wrapper py-3">
                                    {
                                        onboardCard.map((item, key) => {
                                            return (
                                                <div className="e-dashboard-card p-4">
                                                    <div className="row p-1">
                                                        <div className="col-6">
                                                            <img src={item.icon} />
                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <span
                                                                className="e-step"
                                                            // style={{ color: key + 1 === currentStep.step && "#5A69EE" }}
                                                            >
                                                                STEP {`${key + 1}/4`}
                                                            </span>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <h6>
                                                                <span className="position-relative">
                                                                    {item.title}
                                                                    <img
                                                                        src={Info}
                                                                        className="ms-2"
                                                                        onMouseEnter={() => {
                                                                            setOpenToolTip(key)
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            setOpenToolTip(null);
                                                                        }}
                                                                    />
                                                                    {
                                                                        openToolTip === key && (
                                                                            <div className="e-tool-tip p-3">
                                                                                {item.tooltip}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </span>

                                                            </h6>
                                                            <p>
                                                                {item.desc}
                                                            </p>
                                                        </div>
                                                        <div className="col-12 justify-content-between d-flex">
                                                            {/* <EditBtn step={key + 1} /> */}
                                                            {console.log(props.step, key)}
                                                            {
                                                                props.step === key + 1 && (



                                                                    <PrimaryBtn
                                                                        className="e-dashboard-onboard-card-btn mt-3"
                                                                        name={

                                                                            "Let's Start"

                                                                        }
                                                                        image={BtnArrow}
                                                                        right={true}
                                                                        iconClass="ms-2"
                                                                        handleClick={() => {
                                                                            navigator(item.link)
                                                                            props.updateStep()
                                                                            if (item.link === "/basket") {
                                                                                props.callBack()
                                                                            }
                                                                        }}
                                                                    />

                                                                )
                                                            }

                                                            {/* {
                                                    key + 1 < currentStep.step && (
                                                        <div className="e-completed">
                                                            <img src={Completed} alt="" className="me-2" />Completed</div>
                                                    )
                                                } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            {/* Video section */}

                            <div className="row pe-2 pe-lg-0 pe-md-0">
                                <div className="col-12">
                                    <h6 className="e-dashboard-greeting mt-2 mb-3">
                                        Recent deals
                                    </h6>
                                </div>
                                {
                                    list && list.map((item, key) => {
                                        return (
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                <DealCard
                                                    thumbnail={Thumbnail}
                                                    data={item.property}
                                                    inBasket={item.in_basket}
                                                    published={item.is_published}
                                                    unpublished={() => {
                                                        // _getDashboardData()
                                                    }}
                                                    added={(data) => props.added(data)}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>


                            {/* FAQ */}

                        </div>
                    </div>
            }
        </>
    )
}

export default DashBoard;