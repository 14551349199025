
// import packages

// import style
import style from '../../styles/components/nodata.module.scss';
import { useNavigate } from 'react-router-dom';

// import assets
import Illustration from '../../assets/images/new-design/no-data.svg';
import PlusIcon from '../../assets/images/new-design/plus-circle.svg';

//import components
import { PrimaryBtn } from '../cta';

const NoAssets = (props) => {
    const navigate = useNavigate();

    return (
        <div className={`${style.e_nodata_asset_wrapper} col-lg-12 text-center mt-5 pt-3`}>
            <img src={Illustration} />
            <h5>No deals in basket</h5>

            <p className='mb-0'>
                <span>Add deals to your basket</span> and get started now
            </p>
            <PrimaryBtn
                name="Add deals"
                className={style.e_add_assets}
                left={true}
                image={PlusIcon}
                iconClass="me-2"
                handleClick={() => {
                    navigate("/")
                }} />
        </div>
    )
}

export default NoAssets;